import React from "react";
const LoadingSpinner = () => {
  return (
    <div className={"animate__animated animate__fadeIn"} style={{width: "100%", height: "100%", display:"block", color: "#333", background: "rgba(255,255,255,0.7)", position: "fixed", top:0,left:0,zIndex:10, display: "flex"}}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{margin: "auto", display: "block", shapeRendering: "auto"}}
      width="150px"
      height="150px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
     
    >
      <path
        fill="none"
        stroke="#004457"
        stroke-width="16"
        stroke-dasharray="128.29446411132812 128.29446411132812"
        d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z"
        stroke-linecap="butt"
        style={{transform:"scale(0.8)",transformOrigin:"50px 50px"}}
      >
        <animate
          attributeName="stroke-dashoffset"
          repeatCount="indefinite"
          dur="1.6408163265306123s"
          keyTimes="0;1"
          values="0;256.58892822265625"
        />
      </path>
    </svg>
    
    </div>
  );
};

export default LoadingSpinner;
