import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import { orange, purple, green } from "@mui/material/colors";
import { primaryColor, secondaryColor } from "./constants/constants";
import { LoadScript } from "@react-google-maps/api";

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Neue Montreal', 'sans-serif',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },

  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    success: {
      main: "rgb(0, 255, 0)",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <LoadScript
      libraries={["places", "geometry"]}
      googleMapsApiKey="AIzaSyB7clkCriU1xZL6lve7eHS_9w6bhMzigws"
    >
      
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
     
    </LoadScript>
  </React.StrictMode>,
  document.getElementById("root")
);
