// import firebase from 'firebase/app'
// import 'firebase/firestore'
// import 'firebase/auth'

// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCs-N39oeHbWIfabWeglCSRq-hNu3nPxiM",
//   authDomain: "vidanweb-37548.firebaseapp.com",
//   databaseURL: "https://vidanweb-37548-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "vidanweb-37548",
//   storageBucket: "vidanweb-37548.appspot.com",
//   messagingSenderId: "983247346031",
//   appId: "1:983247346031:web:9b8ed15168ca88004eb09a",
//   measurementId: "G-8DE7R27YSD"
// };

// // init firebase
// firebase.initializeApp(firebaseConfig)

// // init services
// const projectFirestore = firebase.firestore()
// const projectAuth = firebase.auth()

// // timestamp
// const timestamp = firebase.firestore.Timestamp

// export { projectFirestore, projectAuth, timestamp }
// import firebase from "firebase";
// import { initializeApp } from 'firebase/app';
// import { getFirestore } from 'firebase/firestore';
// import { getAuth, GoogleAuthProvider } from 'firebase/auth';



// export const firebaseApp = firebase.initializeApp(firebaseConfig);


// export const db = getFirestore(firebaseApp);
// export const auth = firebaseApp.auth();
// // export const provider = new GoogleAuthProvider();
// // provider.setCustomParameters({ prompt: 'select_account' });



import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";

import { getFirestore, Timestamp, FieldValue, collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref } from "firebase/storage";
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyC3V2k7Is-lHoTtEBnObWFnP1Dj3dLJ50I",
  authDomain: "neovidan-b60ad.firebaseapp.com",
  projectId: "neovidan-b60ad",
  storageBucket: "neovidan-b60ad.appspot.com",
  messagingSenderId: "258699706645",
  appId: "1:258699706645:web:24f03925003f27d826e91c"
};

export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);

// Get a list of cities from your database
async function getCities(db) {
  const citiesCol = collection(db, 'properties');
  const citySnapshot = await getDocs(citiesCol);
  const cityList = citySnapshot.docs.map(doc => doc.data());
  console.log(cityList)

  const docRef = doc(db, "properties", "AgGpYOEdSQvYLlyELIkq");
const docSnap = await getDoc(docRef);

if (docSnap.exists()) {
  console.log("Document data:", docSnap.data());
} else {
  // doc.data() will be undefined in this case
  console.log("No such document!");
}

  return cityList;
}

getCities(db);

