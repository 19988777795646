import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Link, useNavigate } from "react-router-dom";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import TableChartIcon from "@mui/icons-material/TableChart";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import GroupsIcon from "@mui/icons-material/Groups";
import { Avatar, Button } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/config";
import { signOut } from "firebase/auth";
import LoadingSpinner from "pages/backoffice/LoadingSpinner";
import { Outlet } from "react-router-dom";
import { secondaryColor } from "constants/constants";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [user, loading, error] = useAuthState(auth);
  let history = useNavigate();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate()

  const pages = [
    {
      title: "Suchprofile",
      link: "/searchprofiles",
      icon: <TroubleshootIcon />,
    },
    { title: "Immobilien", link: "/backoffice", icon: <CorporateFareIcon /> },

    { title: "Einheiten", link: "/units", icon: <CalendarViewMonthIcon /> },
    { title: "Mitarbeiter", link: "/consultants", icon: <GroupsIcon /> },
    { title: "Feldeinstellungen", link: "/fields", icon: <TableChartIcon /> },
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ boxShadow: "none" }}>
        <Toolbar sx={{ background: "white", boxShadow: "none" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <ChevronRightIcon sx={{ color: "#333", fontSize: "30px" }} />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {!open && (
              <img
                src={
                  "vidan_logo.svg"
                }
                style={{  marginTop: "10px" }}
                width={"100"}
                className={"animate__animated animate__fadeIn animate__slower"}
              />
            )}
          </Typography>
          <Typography sx={{ mr: 2, color: "#333", ml: "auto" }}>
            {" "}
            {user && <span>Angemeldet als {user.email}</span>}
          </Typography>

          {user && (
            <Button
              onClick={() => {
                signOut(auth);
              }}
              variant="outlined"
              color={"secondary"}
              sx={{ mr: 2,float: "right", "& :hover":{backgroundColor: secondaryColor, background: secondaryColor,}, border:0, color: "white", borderRadius: "32px", background: secondaryColor }}
                  
            >
              Logout
            </Button>
          )}

          <Box sx={{ flexGrow: 0 }}>
            <IconButton sx={{ p: 0 }}>
              <Avatar
                alt="Remy Sharp"
                src="https://cmspics.onoffice.de/VidanRealEstate/Homepage/Marcel_Jasmund2.jpg"
              />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ justifyContent: "space-around", pt: 3 }}>
          {open && (
            <img
              src={
                "vidan_logo.svg"
              }
            
              width={"140"}
              className={"animate__animated animate__fadeIn animate__slower"}
            />
          )}

          {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton> */}
        </DrawerHeader>
        {/* <Divider /> */}
        <List>
          {pages.map((item, index) => (
            <ListItem
              onClick={() => navigate(item.link)}
              key={item.title}
              disablePadding
              sx={{ display: "block", margin: "0" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    className={"animate__animated animate__fadeIn"}
                    primary={item.title}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <Divider/>
         
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block', margin: "0" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>  */}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {props.children}
          <Outlet />
      </Box>
    </Box>
  );
}
