import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
// import { useAuthContext } from './hooks/useAuthContext'

// pages & components
// import Home from "./pages/home/Home";
// import Map from "./pages/map/Map";
// import Login from "./pages/login/Login";
// import Signup from "./pages/signup/Signup";
// import Navbar from "./components/Navbar";
// import Landing from "./pages/landing/Landing";
// import Footer from "./components/Footer";
// import Backoffice from "./pages/backoffice/Backoffice";
// import PropertiesOverview from "./pages/backoffice/PropertiesOverview";
// import { FieldManagement } from "./pages/backoffice/FieldManagement";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase/config";
// import AggregateManagement from "./pages/backoffice/AggregateManagement";
// import TimeLine from "./pages/backoffice/TimeLine";
// import PropertyDetailsPage from "./pages/backoffice/PropertyDetailsPage";
// import Deal from "./pages/backoffice/Deal";
// import Functions from "./pages/functions/Functions";
// import OnboardingForm from "./pages/landing/OnboardingForm";
// import SearchProfiles from "./pages/backoffice/SearchProfiles";

import Page from "./components/Page";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
// import VidanCampus from "views/VidanCampus";

// import VidanLanding from "views/VidanLanding";
// import UnitsTable from "pages/backoffice/UnitsTable";
// import Consultants from "pages/backoffice/Consultants";
// import ConsultantPage from "pages/landing/ConsultantPage";
// import { SidebarNav } from "layouts/Main/components/Sidebar/components";
// import { Sidebar } from "layouts/Main/components";
import MiniDrawer from "components/MiniDrawer";
// import PortalOverview from "pages/backoffice/PortalOverview";
import { LicenseInfo } from '@mui/x-license-pro';
import  loadable  from "@loadable/component";

LicenseInfo.setLicenseKey('eb63344d4592e2e5a05868de77fd7170Tz03MDEzMixFPTE3MjAxODEyOTExNTMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const FooterLoadable = loadable(() => import('./components/Footer'))
const LoginLoadable = loadable(() => import('./pages/login/Login'))
const DealLoadable = loadable(() => import('./pages/backoffice/Deal'))
const PropertyDetailsPageLoadable =  loadable(() => import('./pages/backoffice/PropertyDetailsPage'))
const BackofficeLoadable = loadable(() => import('./pages/backoffice/Backoffice'))
const VidanCampusLoadable = loadable(() => import('views/VidanCampus'))
const AggregateManagementLoadable = loadable(() => import('./pages/backoffice/AggregateManagement'))
const ConsultantPageLoadable = loadable(() => import('pages/landing/ConsultantPage'))
const OnboardingFormLoadable = loadable(() => import('./pages/landing/OnboardingForm'))
const TimeLineLoadable  = loadable(() => import('./pages/backoffice/TimeLine'))
const FieldManagementLoadable = loadable(() => import('./pages/backoffice/FieldManagement'))
const PropertiesOverviewLoadable = loadable(() => import('./pages/backoffice/PropertiesOverview'))
const SearchProfilesLoadable = loadable(() => import('./pages/backoffice/SearchProfiles'))
const UnitsTableLoadable = loadable(() => import('pages/backoffice/UnitsTable'))
const ConsultantsLoadable = loadable(() => import('pages/backoffice/Consultants'))
const PortalOverviewLoadable = loadable(() => import('pages/backoffice/PortalOverview'))
function App() {
  // const { authIsReady, user } = useAuthContext()
  const [user, loading, error] = useAuthState(auth);
  if (loading) {
    return <></>;
  }
  if (error) {
    return <></>;
  } else {
    return (
      <div className="App">
        {/* <Navbar /> */}

        <BrowserRouter>
          <Routes>
            {/* <Route
              exact
              path="/"
              element={
                <Page>
                  <VidanLanding />
                </Page>
              }
            /> */}

            <Route
              exact
              path="/"
              element={
                <Page>
                  <VidanCampusLoadable />
                </Page>
              }
            />

            {/* <Route
              exact
              path="/vidannewsroom"
              element={
                <Page>
                  <VidanNewsroom />
                </Page>
              }
            /> */}

            <Route
              path="/consultant/:slug"
              element={
                <>
                  <ConsultantPageLoadable />{" "}
                </>
              }
            />

            <Route exact path="/los-gehts" element={<OnboardingFormLoadable />} />
            {/* <Route
              exact
              path="/thefont"
              element={
                <Page>
                  <Rental />
                </Page>
              }
            /> */}
            <Route path="/" element={<MiniDrawer/>}>
            <Route
              exact
              path="/backoffice/:id"
              element={
                user ? (
                  <>
                   
                    <BackofficeLoadable />
                  </>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
             <Route
              exact
              path="/backoffice/aggregate/:id"
              element={
                user ? (
                  <>
                    {" "}
                    
                    <AggregateManagementLoadable />
                  </>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            <Route
              exact
              path="/backoffice/timeline/:id"
              element={
                user ? (
                  <>
                    <TimeLineLoadable />
                  </>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
             <Route
              exact
              path="/new-property"
              element={
                user ? (
                  <>
                    {" "}
                    <BackofficeLoadable />{" "}
                  </>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            <Route
              exact
              path="/fields"
              element={
                user ? (
                  <>
                    {" "}
                    <FieldManagementLoadable />
                  </>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            <Route
              path="/backoffice"
              element={
                user ? (
                  <>
                    <PropertiesOverviewLoadable />{" "}
                  </>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            <Route
              path="/searchprofiles"
              element={
                user ? (
                  <>
                    <SearchProfilesLoadable />{" "}
                  </>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            <Route
              path="/units"
              element={
                user ? (
                  <>
                    <UnitsTableLoadable />{" "}
                  </>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />

            <Route
              path="/consultants"
              element={
                user ? (
                  <>
                    <ConsultantsLoadable />{" "}
                  </>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            </Route>
            <Route
              exact
              path="/customer-portal/:id"
              element={
                <Page>
                  <PortalOverviewLoadable />
                </Page>
              }
            />

           
            <Route
              exact
              path="/property/:id/:unitId?/:searchProfileId?"
              element={
                <>
                  <PropertyDetailsPageLoadable />
                   {/* <FooterLoadable /> */}
                </>
              }
            />
             <Route
              exact
              path="/object/:id/:unitId?/:searchProfileId?"
              element={
                <>
                  <PropertyDetailsPageLoadable limitedView /> 
                  {/* <FooterLoadable /> */}
                </>
              }
            />
            <Route
              exact
              path="/deal/:id"
              element={
                user ? (
                  <>
                    <DealLoadable />
                    {/* <FooterLoadable /> */}
                  </>
                ) : (
                  <>
                    <DealLoadable />
                    {/* <FooterLoadable /> */}
                  </>
                )
              }
            />

           

            <Route
              path="/login"
              element={
                <>
                  {user && <Navigate to="/backoffice/" />}

                  <LoginLoadable />
                </>
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
